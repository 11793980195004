import React, { Component } from 'react';
import Parse from 'parse';
import $ from "jquery";
import './challenge.css';
import SaveNotification from "./saveNotification.jsx";
class CreateChallenge extends Component {

  constructor(props) {
        super(props);
        Parse.initialize("ze4eQAdmycW7e8MCskILkfGFUNdwYnsKSsZDi0t5", "sDC1XmRXe4bP5i1VqhBhy8cIdiWrBpJmvtmmwvIw"); 
        Parse.serverURL = "https://parse.joelskitchen.com/parse";

        let currentUser = Parse.User.current();   
        
        if(!currentUser)
        {
            window.location.href = "/";
        }
        this.state = {
            parseFile_210:'',
            parseFile_640:'',
            challenge_title_msg:null,
            challenge_image_msg:null,
            challenge_caption_msg:null,
            more_creators:false,
            more_content:false,
            create_message:null,
            create_message_show:false,
            challenge_image_msg_size:false,
            lastjob_number:0,
            isNotificationOpen:false,
            post_Notification_Id:'',
            post_Notification_Msg:'',
            post_Notification_Comment:''
        };
         this.handleFileChange = this.handleFileChange.bind(this);
         this.discardImage = this.discardImage.bind(this);
         this.handleChallengeSubmit = this.handleChallengeSubmit.bind(this);
         this.handleMoreCreators = this.handleMoreCreators.bind(this);
         this.handleMoreContent = this.handleMoreContent.bind(this); 
         this.checkChallengeDiscard = this.checkChallengeDiscard.bind(this); 
         this.challengeCancel = this.challengeCancel.bind(this); 
         this.checkcloseCancel = this.checkcloseCancel.bind(this);
         this.checkJobNumber();
         
    }
    checkJobNumber() {
            var self = this;  
            /*get last job number*/
            const ClassObject = Parse.Object.extend('Photo'); 
            const queryjob = new Parse.Query(ClassObject);

            // Sort by the field in descending order to get the latest entry
            queryjob.descending('jobNumber');
            queryjob.limit(1);

            queryjob.find().then(function(queryrresult){
                if(queryrresult){
                  console.log(queryrresult[0]);     
                  console.log(queryrresult[0].get('jobNumber'));                 
                  self.setState({lastjob_number:queryrresult[0].get('jobNumber')+1});  
                }
            })
    }
    
    challengeCancel(event) {
       $('.createChallange__mn #challenge_cancel').trigger('click');
    }
    checkcloseCancel(event) {
     setTimeout(() => {
          window.location.href = "/business/challenges";
         },500);
    }
    checkChallengeDiscard(event) {
       $("#photoUrlInput").val('');
       $("#dataurl_210").val('');
       $("#dataurl_210_img").val('');
       $("#dataurl_640").val('');
       $("#dataurl_640_img").val('');     
       $("#challenge_title").val('');
       $("#challenge_caption").val('');
       //$("#challengeHashtag").val('');
       $("#profilePicturePreview").parent(".img_view").attr("style", "display: none");
       document.getElementById("profilePicturePreview").src = "/images/defaultcamera.png";
       $(".content-wrap .file-input").attr("style", "display: block");
       $(".content-wrap .img_view").attr("style", "display: none");
       this.setState({more_creators:false});    
       this.setState({more_content:false});  
       $("#more_creators").prop("checked", false);
       $("#more_content").prop("checked", false);   
       window.history.back();      
    }
    handleMoreCreators(event) {
        this.setState({more_creators:event.target.checked});    
    }
    handleMoreContent(event) {
        this.setState({more_content:event.target.checked});    
    }
toggleNotification = (postid,msg,comment) => {   console.log('toggleNotification'+postid);
      var self = this;
        self.setState(prevState => ({
          isNotificationOpen: !prevState.isNotificationOpen,
          post_Notification_Id: postid,
          post_Notification_Msg: msg,
          post_Notification_Comment:comment  
        }));
        //self.setState({ post_Notification_Id: postid });        
      };
    handleChallengeSubmit(event) {
     event.preventDefault();
     var forms = document.forms.frm_challenge;
     var challenge_title=forms.challenge_title.value;
     var challenge_caption=forms.challenge_caption.value;
     //var challengeHashtag=forms.challengeHashtag.value;
     var dataurl_210=forms.dataurl_210.value;
     var dataurl_640=forms.dataurl_640.value;   
     var dataurl_210_img=forms.dataurl_210_img.value;
     var dataurl_640_img=forms.dataurl_640_img.value;
     var more_creators_val=this.state.more_creators;
     var more_content_val=this.state.more_content;
     var more_creators=0;
     var more_content=0;
     var self = this;  
     if(dataurl_210==='' || dataurl_210_img==='')
         {
            self.setState({challenge_image_msg:'Please upload image.'});     
         }
          if(challenge_title==='')
         {
            self.setState({challenge_title_msg:"Please write some challenge title!"}); 
         }
          if(challenge_caption==='')
         {
            self.setState({challenge_caption_msg:"Please write some challenge caption!"}); 
         }
         if(dataurl_210!=='' && challenge_title!=='' && challenge_caption!=='')
         {
          if(more_creators_val == 1)
          {
            more_creators=1;
            more_content=0;
          }
          if(more_content_val == 1)
          {
            more_content=1;
            more_creators=0;
          }
           const currentUser = Parse.User.current();
            if (currentUser) {
                const parseFile_210 = new Parse.File('image.jpg', { base64: dataurl_210 });
                const parseFile_640 = new Parse.File('thumbnail.jpg', { base64: dataurl_640 });
                const Photo = Parse.Object.extend('Photo');
                const photo = new Photo();
                let currentUser = Parse.User.current();  
                //let randomnumber=Math.floor(Math.random() * 900) + 100;   
                let jobNumber=this.state.lastjob_number?this.state.lastjob_number:1; 

                photo.set('image', parseFile_640);  
                photo.set('thumbnail', parseFile_210);
                photo.set('nonMealPost', 1);
                photo.set('prize', "");
                photo.set('user', currentUser);
                photo.set('isChallenge', "1");
                photo.set('caption', challenge_caption);
                photo.set('fulltext', challenge_caption);
                photo.set('challengeTitle', challenge_title);  
                //photo.set('challengeHashtag', challengeHashtag);  
                photo.set('more_content', more_content);
                photo.set('more_creators', more_creators);
                photo.set('challengeStatus', 'Pending');
                photo.set('impr', 0);
                photo.set('businessAccount', Parse.User.current().get("displayUsername"));
                photo.set('businessAccountUser', Parse.User.current());
                photo.set('jobNumber', jobNumber);

                
                /*get last job number*/

                 
                    photo.save().then((photodata) => {
                      setTimeout(() => {
                           $('.createChallange__mn #challenge_submitted').trigger('click');
                           $("#photoUrlInput").val('');
                           $("#dataurl_210").val('');
                           $("#dataurl_210_img").val('');
                           $("#dataurl_640").val('');
                           $("#dataurl_640_img").val('');     
                           $("#challenge_title").val('');
                           $("#challenge_caption").val('');
                           //$("#challengeHashtag").val('');
                           $("#profilePicturePreview").parent(".img_view").attr("style", "display: none");
                           document.getElementById("profilePicturePreview").src = "/images/defaultcamera.png";
                           $(".content-wrap .file-input").attr("style", "display: block");
                           $(".content-wrap .img_view").attr("style", "display: none");
                           this.setState({more_creators:false});    
                           this.setState({more_content:false});  
                           $("#more_creators").prop("checked", false);
                           $("#more_content").prop("checked", false);
                            self.toggleNotification(photodata.id,'Thank you for submitting your challenge. It is currently pending review.','');
                           self.setState({isNotificationOpen:false,post_Notification_Id:'',post_Notification_Msg:'',post_Notification_Comment:''});
                           /*self.toggleNotification(photodata.id,'created a challenge','');
                           self.setState({isNotificationOpen:false,post_Notification_Id:'',post_Notification_Msg:'',post_Notification_Comment:''});*/
                      }, 500);
                     }).catch((error) => {
                        
                    });    
            }


          
         }
         
          setTimeout(() => {
             self.setState({challenge_image_msg:null});  
             self.setState({challenge_title_msg:null}); 
             self.setState({challenge_caption_msg:null}); 
        }, 5000);
       }
  discardImage = function(e){
     $("#photoUrlInput").val('');
     $("#dataurl_210").val('');
     $("#dataurl_210_img").val('');
     $("#dataurl_640").val('');
     $("#dataurl_640_img").val('');     
     //$("#caption").val('');
     $("#profilePicturePreview").parent(".img_view").attr("style", "display: none");
     document.getElementById("profilePicturePreview").src = "/images/defaultcamera.png";
     $(".content-wrap .file-input").attr("style", "display: block");
     $(".content-wrap .img_view").attr("style", "display: none");
  }
handleFileChange = (event) => {
      
    const file = event.target.files[0];   
    var self = this;  
    if(file)
    {

    const fileSizeInBytes = file.size; 
    const fileSizeInKB = fileSizeInBytes / 1024; 
    const maxSizeInKB = 20480; // 20MB

    if (fileSizeInKB > maxSizeInKB) {
       self.setState({create_message_show:true}); 
       self.setState({create_message:"File size exceeds the limit."});        
    } else {
     const fileType = file.type;    

    if (fileType === 'image/jpeg' || fileType === 'image/png') {
                let imageFile = event.target.files[0];
                var reader = new FileReader();
                reader.onload = function (e) {
                var img = document.createElement("img");
                img.onload = function (event) {
                var custom_width_640=640;
                var custom_width_210=210;
                if(custom_width_640)
                {
                    var canvas = document.createElement("canvas");
                    const canvasResize = document.createElement('canvas');
                    const ctxResize = canvasResize.getContext('2d');                    
                    const maxWidth = 1000; 
                    const maxHeight = 1000; 
                    const aspectRatio = img.width / img.height;
                     let newHeightCheck=0;
                    let newWidthCheck=0;
                    let newMessageCheck='';

                    let newWidth = img.width;
                    let newHeight = img.height;
                    if (img.width > maxWidth) {
                        newWidth = maxWidth;
                        newHeight = newWidth / aspectRatio;
                    }
                    if (newHeight > maxHeight) {
                        newHeight = maxHeight;
                        newWidth = newHeight * aspectRatio;
                    }
                    if(custom_width_640>newHeight || custom_width_640>newWidth )
                    {
                        if(custom_width_640>newHeight)
                        {
                            newHeightCheck=newHeight;
                            newMessageCheck= "Please upload a larger size image. The height proportionally is "+parseInt(newHeight)+"px, it must be at least 640px";
                        }
                        if(custom_width_640>newWidth)
                        {
                            newWidthCheck=newWidth;
                             newMessageCheck= "Please upload a larger size image. The  width proportionally is "+parseInt(newWidth)+"px, it must be at least 640px";
                        }
                        if(custom_width_640>newHeight && custom_width_640>newWidth)
                        {
                            newMessageCheck= "Please upload a larger size image. The  width/height proportionally is "+parseInt(newWidth)+"px & "+parseInt(newHeight)+"px, it must be at least 640px";
                        }
                          self.setState({challenge_image_msg_size:newMessageCheck});  
                         $("#profilePicturePreview").parent(".img_view").attr("style", "display: none");
                         document.getElementById("profilePicturePreview").src = "/images/defaultcamera.png";
                         $("#photoUrlInput").val('');
                         $("#dataurl_210").val('');
                         $("#dataurl_640").val('');
                         $("#dataurl_640_img").val('');
                         $("#dataurl_210_img").val('');
                         $('#large_challenge_image_btn').trigger('click');
                    }else
                    {

                    canvasResize.width = newWidth;
                    canvasResize.height = newHeight;
                    ctxResize.drawImage(img, 0, 0, img.width, img.height, 0, 0, newWidth, newHeight);
                    const resizedDataURL = canvasResize.toDataURL('image/jpeg');
                    const canvasCrop = document.createElement('canvas');
                    const ctxCrop = canvasCrop.getContext('2d');
                    const cropWidth = custom_width_640;
                    const cropHeight = custom_width_640;
                    canvasCrop.width = cropWidth;
                    canvasCrop.height = cropHeight;
                    const x = (newWidth - cropWidth) / 2;
                    const y = (newHeight - cropHeight) / 2;
                    ctxCrop.drawImage(canvasResize, x, y, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
                    const croppedDataURL = canvasCrop.toDataURL('image/jpeg');
                    var dataurl_640 = croppedDataURL; 

                    //new
                     if(custom_width_210)
                {
                    var canvas = document.createElement("canvas");                    
                    const canvasResize = document.createElement('canvas');
                    const ctxResize = canvasResize.getContext('2d');                    
                    const maxWidth = 500; 
                    const maxHeight = 500; 
                    const aspectRatio = img.width / img.height;
                    let newWidth = img.width;
                    let newHeight = img.height;

                    if (img.width > maxWidth) {
                        newWidth = maxWidth;
                        newHeight = newWidth / aspectRatio;
                    }
                    if (newHeight > maxHeight) {
                        newHeight = maxHeight;
                        newWidth = newHeight * aspectRatio;
                    }

                   

                    canvasResize.width = newWidth;
                    canvasResize.height = newHeight;
                    ctxResize.drawImage(img, 0, 0, img.width, img.height, 0, 0, newWidth, newHeight);

                    const resizedDataURL = canvasResize.toDataURL('image/jpeg');

                    const canvasCrop = document.createElement('canvas');
                    const ctxCrop = canvasCrop.getContext('2d');
                    const cropWidth = custom_width_210;
                    const cropHeight = custom_width_210;
                    canvasCrop.width = cropWidth;
                    canvasCrop.height = cropHeight;
                    const x = (newWidth - cropWidth) / 2;
                    const y = (newHeight - cropHeight) / 2;
                    ctxCrop.drawImage(canvasResize, x, y, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
                    const croppedDataURL = canvasCrop.toDataURL('image/jpeg');
                    var dataurl_210 = croppedDataURL;
                    /*new code 1*/
                  
                }
                if(dataurl_640!==undefined || dataurl_210!==undefined)
                {
                const dataurl_210s = document.getElementById('dataurl_210');
                dataurl_210s.value = dataurl_210;
                const dataurl_640s = document.getElementById('dataurl_640');
                dataurl_640s.value = dataurl_640;
                
                const parseFile_210 = new Parse.File('image.jpg', { base64: dataurl_210 });
                const parseFile_640 = new Parse.File('thumbnail.jpg', { base64: dataurl_640 });

                parseFile_640.save().then((savedFile) => {
                 const dataurl_640_img = document.getElementById('dataurl_640_img');
                 dataurl_640_img.value = savedFile.url();
                }).catch((error) => {
                  console.error('Error saving file:', error);
                });
                parseFile_210.save().then((savedFile) => {
                 const dataurl_210_img = document.getElementById('dataurl_210_img');
                 dataurl_210_img.value = savedFile.url();
                }).catch((error) => {
                  console.error('Error saving file:', error);
                });
                document.getElementById("profilePicturePreview").src = dataurl_640;   
                $("#profilePicturePreview").parent(".img_view").attr("style", "display: block");
                $('#discard_image').attr("style", "display: block");
                $(".content-wrap .file-input").attr("style", "display: none");
                $(".content-wrap .img_view").attr("style", "display: block");
              }


                    //new
                   }
                }
               
                    
            }
                    img.src = e.target.result;
                }
                reader.readAsDataURL(imageFile);
            }else {
               self.setState({create_message_show:true});  
               self.setState({create_message:'Unsupported file type.'}); 
              // You can display an error message or take other actions here
            }
          }
          setTimeout(() => {
          self.setState({create_message_show:false});  
          self.setState({create_message:null}); 
          self.setState({photo_image_message:null});     
          self.setState({challenge_image_msg:null});  
                
        }, 3000);
     }
  


};
onSiteChanged = (e) => {
    if(e.currentTarget.value == 1){
        this.setState({more_creators: 1});
        this.setState({more_content: 0});
    }
    if(e.currentTarget.value == 2){
        this.setState({more_creators: 0});
        this.setState({more_content: 1});
    }
};
  render() {
var isNotificationOpen=this.state.isNotificationOpen; 
var post_Notification_Ids=this.state.post_Notification_Id; 
var post_Notification_Msg=this.state.post_Notification_Msg; 
var post_Notification_Comment=this.state.post_Notification_Comment;
    return (
      <React.Fragment>{isNotificationOpen?<SaveNotification   isOpen={isNotificationOpen}  postNotificationid={post_Notification_Ids} postMsg={post_Notification_Msg} postPhotoid={post_Notification_Ids} postActivityComment={post_Notification_Comment}  />:""}  
        <div className='createChallange__mn'>
          <div className='container'>
              <form className="frm_challenge"  id="frm_challenge" onSubmit={this.handleChallengeSubmit}>
            <div className='wrapper'>
              <div className='top-part'>
                <div className='title-main'>
                  <h3><a href='/business/challenges'><img src="/images/back_top.svg" alt="Back to Top"/> </a> Add Challenge</h3>
                  <p>Create a challenge to reach more creators and build more content for your brand. </p>
                </div>
              </div>
             
              <div className='bottom-part'>
                <div className='inner_wrap'>
                  <aside className='card-wrap preview-challenge'>
                    <div className='title-wrap'>
                      <h3>Challenge Photo</h3>
                    </div>
                    <div className='content-wrap'>
                      <div className='file_upld'>
                        <div className='img_view' style={{display:"none"}}>
                  <button type="button" style={{display:"none"}} onClick={this.discardImage}  id="discard_image"  className='edit_icon' >
                
                            <img src="/images/img_edit.png" alt="Photo edit" />  
                        
                  </button>
                          <img height="" width="250" id="profilePicturePreview" src="/images/defaultcamera.png" alt="photo Picture" />
                        </div>
                        <div className='file-input'>
                          <label htmlFor="myFile">                       
                            <input type="hidden" id="photoUrlInput" name="photoUrl"/>
                            <input type="hidden" id="dataurl_210" name="dataurl_210"/>
                            <input type="hidden" id="dataurl_640" name="dataurl_640"/>
                            <input type="hidden" id="dataurl_640_img" name="dataurl_640_img"/>
                            <input type="hidden" id="dataurl_210_img" name="dataurl_210_img"/>
                            <img src="/images/file_upload.svg" alt="Joel's Kitchen"/>
                            <span>Choose a file to upload.</span>
                          </label>
                          <input type="file" id="myFile"   onChange={this.handleFileChange}    accept=".jpg, .jpeg, .png"  name="filename"  />
                          <div className='file-infor'>
                          <label>We recommend using high quality .jpg & .png files less than 20MB.</label> 
                        </div>
                        </div>
                        
                        
                      </div>
                      <p className='create_message_alert'>{this.state.challenge_image_msg}{this.state.create_message}</p>
                    </div>
                  </aside>
                  <aside className='card-wrap  create-challenge'>
                    <div className='title-wrap'>
                      <h3>Create Challenge <a href='javascript:void(0);' type="button" data-toggle="modal" data-target="#createChallengeModal"><img src="/images/info-icon.svg" alt="Info Icon"/></a></h3>
                    </div>
                    <div className='content-wrap frm-wrapper'>
                      <div className='frm-grp'>
                        <label className='frm-lbl'>Title</label>
                        <input type="text" placeholder='Give your challenge a title.' name="challenge_title"  autocomplete="off" className="frm-cntrl" id="challenge_title" />
                           <p className='create_message_alert'>{this.state.challenge_title_msg}</p>
                      </div>
                      {/*<div className='frm-grp'>
                        <label className='frm-lbl'>Hashtag</label>
                        <input type="text"  autocomplete="off" placeholder='Add a hashtag to give your challenge a grand kick off.' name="challengeHashtag" className="frm-cntrl" id="challengeHashtag" />
                          
                      </div>*/}
                      <div className='frm-grp'>
                        <label className='frm-lbl'>Creative Direction</label>
                        <textarea  autocomplete="off" placeholder='Create a fun and simple description to guide creators.' name="challenge_caption" className="frm-cntrl" id="challenge_caption" />
                            <p className='create_message_alert'>{this.state.challenge_caption_msg}</p>
                      </div>
                    </div>
                  </aside>
                  <aside className='card-wrap goal_challenge'>
                    <div className='title-wrap'>
                      <h3>What is your goal? <a href='javascript:void(0);' type="button" data-toggle="modal" data-target="#challengeGoalsModal"><img src="/images/info-icon.svg" alt="Info Icon"/></a></h3>
                      <p>Choose a goal for your challenge.</p>
                    </div>
                    <div className='content-wrap frm-wrapper'>
                      <ul>
                        <li>
                        {/*  <input type="radio" id="more_creators" name="goal_challenge" value="More Creators" />*/}
                          <input className="form-check-input" type="radio" value="1" name="more_creators_grp" id="more_creators"  onChange={this.onSiteChanged}  />
                        {/*    <label className="form-check-label" for="more_creators">*/}
                          <label for="more_creators">
                            <h6>More Creators</h6>
                            <p>Get more creators for your brand</p>
                          </label>
                        </li>
                        <li> 
                       {/*   <input type="radio" id="more_content" name="goal_challenge" value="More content" />*/}
                          <input className="form-check-inputs" type="radio" value="2" name="more_creators_grp" id="more_content"   onChange={this.onSiteChanged}  />
                          <label for="more_content">
                            <h6>More Content</h6>
                            <p>Get more content for your brand</p>                          
                          </label>
                        </li>
                      </ul>  
                    </div>
                  </aside>
                  <div className="action_grp btn-group">
                    <div class="cta_btn fourth-btn">
                      <button type="button" className='btn-canl'   onClick={this.challengeCancel} >Cancel</button>
                    </div>
                    <div class="cta_btn third-btn">
                        <button className='btn-submt'>Submit </button>
                     {/* <button type="button" className='btn-submt'>Submit </button>*/}
                    </div>
                  </div>
                  <div className="chalng_info text-center">
                    <p>Challenges are reviewed within 24 hours. <br />By creating a challenge you agree to the <a href='javascript:void(0)'>Challenge Rules.</a></p>
                  </div>
                </div>               
              </div>             
            </div>
          </form>
          </div>
          {/*popup*/}
             <button href='javascript:void(0);' id="challenge_submitted" type="button" data-toggle="modal" data-target="#chnlgPostConfrModal"  style={{display:"none"}}>submit</button>

           <div className="modal fade post_modal post_modal_overlay createPostCloseModal chnlgPostConfrModal" id="chnlgPostConfrModal" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="createPostCloseTitle" aria-hidden="true">
          <div className='bg_overlay'></div>
          <div className="modal-dialog modal-md modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className='inner text-center'>
                  <div className='title-main'>
                    <h6>Thank you for submitting your challenge!</h6>
                    <p>Joel’s Kitchen will contact you within 24 hours to discuss budget and payment. </p>
                  </div>
                  <div className='action-part'>
                    <a href='javascript:void(0);' className='btn cancel  btn-post-cancel'  onClick={this.checkcloseCancel} data-dismiss="modal" aria-label="Close" title='Cancel'>Close</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

         <button href='javascript:void(0);' id="challenge_cancel" type="button" data-toggle="modal" data-target="#challengCloseModal"  style={{display:"none"}}>submit</button>

        <div className="modal fade post_modal post_modal_overlay createPostCloseModal" id="challengCloseModal" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="createPostCloseTitle" aria-hidden="true">
          <div className='bg_overlay'></div>
          <div className="modal-dialog modal-md modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className='inner text-center'>
                  <div className='title-main'>
                    <h6>Are you sure you want to leave?</h6>
                    <p>If you exit, your challenge won’t be saved.</p>
                  </div>
                  <div className='action-part'>
                    <a href='javascript:void(0);' className='btn danger btn-post-discard' data-dismiss="modal" aria-label="Close" title='Discard'  onClick={this.checkChallengeDiscard}>Discard</a>
                    <a href='javascript:void(0);' className='btn cancel  btn-post-cancel'  onClick={this.checkCancel} data-dismiss="modal" aria-label="Close" title='Cancel'>Cancel</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

         <button href='javascript:void(0);' id="large_challenge_image_btn" type="button" data-toggle="modal" data-target="#large_challenge_image"  style={{display:"none"}}>submit</button>
           <div className="modal fade post_modal post_modal_overlay createPostCloseModal large_challenge_image" id="large_challenge_image" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="createPostCloseTitle" aria-hidden="true">
          <div className='bg_overlay'></div>
          <div className="modal-dialog modal-md modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className='inner text-center'>
                  <div className='title-main'>
                    <p>{/*Please upload a larger size image!*/}{this.state.challenge_image_msg_size}</p>
                    
                  </div>
                  <div className='action-part'>
                    <a href='javascript:void(0);' className='btn cancel  btn-post-cancel' data-dismiss="modal" aria-label="Close" title='Close'>Close</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          {/*popup*/}
        </div>
      </React.Fragment>
    )
  }
}

export default CreateChallenge;
